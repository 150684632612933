@import url("https://use.typekit.net/fvk4nfp.css");

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-Black.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-Bold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-ExtraBold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler.woff2") format("woff2"),
    url("./Assets/Fonts/Butler.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./Assets/Fonts/raleway-v28-latin-regular.woff2') format('woff2'),
       url('./Assets/Fonts/raleway-v28-latin-regular.woff') format('woff'),
       url('./Assets/Fonts/raleway-v28-latin-regular.ttf') format('truetype'),
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('./Assets/Fonts/raleway-v28-latin-500.woff2') format('woff2'),
       url('./Assets/Fonts/raleway-v28-latin-500.woff') format('woff'),
       url('./Assets/Fonts/raleway-v28-latin-500.ttf') format('truetype'),
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('./Assets/Fonts/raleway-v28-latin-600.woff2') format('woff2'),
       url('./Assets/Fonts/raleway-v28-latin-600.woff') format('woff'),
       url('./Assets/Fonts/raleway-v28-latin-600.ttf') format('truetype'),
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('./Assets/Fonts/raleway-v28-latin-700.woff2') format('woff2'),
       url('./Assets/Fonts/raleway-v28-latin-700.woff') format('woff'),
       url('./Assets/Fonts/raleway-v28-latin-700.ttf') format('truetype'),
}

$color1:#DC4A37;

$color1opacity30: #FF873030;
$color1opacity42: #FF873042;
$color1opacity60: #FF873060;

$color2: #FF897A;
$color3: #4B4E5D;
$fontcolor: #343333;

$primaryfont: "lato", sans-serif;
$fontRaleway: 'Raleway', sans-serif;

body { background: #E5E5E5; font-family: "lato", sans-serif; color: $color3; margin: auto; overflow-x: hidden; }

.desktop { display: block; }
.mobile { display: none; }

// .App {
//   background: #122845;
// }

header {
  & { background: #ffffff; height: 126px; width: 100%; }
  .header-wrapper { display: flex; align-items: center; justify-content: space-between; height: 126px; max-width: 1100px; width: 100%; margin: 0 auto; display: grid; grid-template-columns: 0.25fr 1fr 0.25fr; }
  .logo { 
    // & { position: absolute; left: 50px; cursor: default; }
    & { padding: 0 10px; width: 70px; }
    img { max-height: 66px; max-width: 208px; }
  }
  .assessment-title { 
    & { flex: 1 1 auto; text-align: center; color: #000000; }
    .logo-text { font-size: 36px; margin: 0 auto; font-weight: 700; line-height: 43px; }
    .logo-subtext { font-size: 21px; margin: 0 auto; font-weight: 700; line-height: 25px; letter-spacing: 8px; color: $color1; }
    img { height: 29px; max-width: 433px; margin-top: 5px; } 
  }
  // .bleat-logo { position: absolute; right: 50px; }
  .bleat-logo { padding: 0 15px 0 0; }
}

main {
  .container { margin: 55px auto; max-width: 1110px; border-radius: 10px; box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.0945336); }
  .loading { display: flex; justify-content: center; align-items: center; }
  
  .start { 
    & { height: 580px; background: #ffffff; display: flex; justify-content: center; border-radius: 5px;}
    .video { 
      & { border-top-left-radius: 5px; border-bottom-left-radius: 5px; position: relative; width: 50%; height: 100%; display: flex; justify-content: center; object-fit: cover; background: #fff; transition: all 0.25s; background-color: #E5E5E5; overflow: hidden; } // justify-content: center; background: #333; overflow: hidden; }
      video { object-fit: cover; transform: scale(100%); max-width: 100%; }
      .thumbnail { position: absolute; height: 100%; width: 100%; overflow: hidden; object-fit: cover; }
      .playbtn { width: 144px; height: 144px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; }
      .playbtn:hover {content: url("../src/Assets/Images/play-button-hover.png"); }
    }
    .start-img-mobile { display: block; margin: 0 auto; }
    .start-img-desktop { display: block; margin: 0 auto; max-width: 100%; height: auto; }
    .intro {
      & { padding: 0 5%; display: flex; flex-direction: column; justify-content: center; width: 41%; transition: all 0.25s; } // width: 38%; }
      h1 { margin: 0; margin-bottom: 38px; font-family: "lato", sans-serif; font-weight: 700; color: $fontcolor; font-size: 36px; line-height: 43px; width: auto; margin-top: -38px; margin-bottom: 52px; }
      h1 br { content: ""; }
      h2 { margin: 0; margin-bottom: 0; font-weight: 300; font-size: 24px; line-height: 31px; color: $fontcolor; max-width: 100%; max-width: auto; }
      .completion { 
        & { display: flex; align-items: center; margin-top: 68px; color: #343333; font-weight: 700; font-size: 18px; }
        .cta { display: block; background: $color1; border-radius: 5px; color: #fff; text-decoration: none; width: 193px; text-align: center; font-family: "lato", sans-serif; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 12px 0 12px; margin-left: 0; }
        .cta:hover { background: $color2; transition: 0.3s; color: #fff; }
        .time-estimate {
          & { margin: 15px auto 15px 60px; }
          .clock-icon { width: 25px; height: 25px; vertical-align: bottom; }
        }
      }
    }
  }

  .pre-assessment { 
    & { color: #ffffff; max-width: 65ch; margin: 45px auto 15px; padding: 15px; font-family: $primaryfont; min-height: 65vh; display: flex; flex-direction: column; justify-content: center; }
    h1 { font-size: 21px; text-transform: uppercase; text-align: center; }
    .pre-assessment-subheader { font-weight: 600; text-align: center; font-size: 18px; }
    .explanation {
      & { display: flex; flex-direction: row; justify-content: center; align-items: center; font-size: 18px; }
      p { font-weight: 500; display: block; }
      .marker { box-sizing: border-box; flex: 0 0 30px; height: 30px; width: 100%; border-radius: 50%; background-color: white; margin: 10px 20px; }
      .red { border: 5px solid #FF2121; }
      .yellow { border: 5px solid #FDBF04; }
      .green { border: 5px solid #73B76F; }
    }
    .cta-link { text-decoration: none; margin: 30px auto 15px; }
    .cta { font-family: $primaryfont; color: #fff; display: block; margin: 0 auto; width: 180px; text-align: center; background-color: #D43324; border-radius: 4px; font-weight: 700; font-size: 18px; letter-spacing: 1px; }
    .cta:hover { background-color: #FF897A; }
  }

  .assessment {
    & { min-height: 580px; background: #fff; width: 95%; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 20px; }
      .progress-percent-text { transition: 0.4s linear; font-family: $primaryfont; font-size: 21px; font-weight: 700; color: #FF8730; margin-top: 10px; margin-bottom: 0; line-height: 25px; width: 4ch; max-width: calc(100% - 3ch); }
      .progress-bar {
        & { display: flex; justify-content: space-between; width: 100%; margin-top: 16px; }
        .bar-section { 
          .progress-percentage { text-align: right; font-weight: 700; font-size: 1.1rem; margin: 1rem 0 0.5rem 1rem; -webkit-transition: all .4s linear; -moz-transition: all .4s linear; -o-transition: all .4s linear; transition: all .4s linear; color: #4B4E5D; }
          .progress-grid { 
            display: grid; grid-template-columns: repeat(3, 1fr); max-width: 42px; margin: 2.5rem auto;
            .grid-square { width: 10px; height: 10px; margin: 2px; } 
          }
          .bar { height: 6px; margin: 0 5px 10px; }
          .section-title { width: 100%; text-align: center; font-family: 'lato', sans-serif; font-weight: 400; color: #343333; font-size: 16px; }
        }

        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 3px; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
      }
      .current-section { font-weight: 400; font-size: 16px; line-height: 19px; text-align: center; letter-spacing: 2px; text-transform: uppercase; color: $color1; }

      .progress-indicator { margin: 70px auto 50px; width: 100%; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: #202945; font-size: 18px; }

      .question { min-height: 150px; max-width: 85%; margin: 0 auto; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: $color3; font-size: 24px; }

      .radio-container {
        & { width: 86%; margin: 0 auto; }
        
        .slider-labels {
          & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; }
          .red.active { color: #FF2121; }
          .yellow.active { color: #FDBF04; }
          .green.active { color: #73B76F; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; }
        }

        .MuiRadio-root { margin-left: -20px; }
        
      }

      .slider-container {
        & { width: 86%; margin: 0 auto; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; color: $color3; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; letter-spacing: 1px; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: $color1; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color1; width: calc(100% + 10px); margin-left: -5px; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color1; }

        .custom-slider .MuiSlider-thumb { background: $color1; }
        .custom-slider .MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; top: -1px; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 120px auto 30px; padding-bottom: 30px; }
        button { color: #343333; font-weight: 600; font-size: 14px; font-family: "lato", sans-serif; }
      }
    }
  }

  .assessment-additional {
    & { min-height: 580px; background: #fff; border-radius: 10px; color: $color3; width: 95%; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 50px; }
      h1 { font-size: 18px; text-align: center; }

      .progress-indicator { margin: 2.5rem auto; width: 100%; text-align: center; font-family: "lato", sans-serif; font-weight: 400; color: $color1; font-size: 18px; }

      .question { min-height: 150px; text-align: center; font-family: "lato", sans-serif; font-weight: 400; font-size: 24px; }
      .textarea { min-height: 80px; }
      .textarea-field { max-width: 540px; width: 80%; margin: 0 auto; margin-bottom: 0; }
      .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 21px; border: 1px solid #979797; padding: 14px 18px; border-radius: 5px; }
      .form-details-text { background-color: #fff; padding: 0 9px 22px; height: 90px; }

      .slider-container {
        & { width: 86%; margin: 0 auto; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #182036; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: $color3; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
          .active { font-weight: 700; color: $color1; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color1; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color1; }

        .custom-slider .MuiSlider-thumb { background: $color1; }
        .custom-slider .MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; top: -2px; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 80px auto 30px; padding-bottom: 30px; }
        button { color: #182036; font-weight: 600; font-size: 16px; font-family: "lato", sans-serif; }
        .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
        .nextbtn:hover { background-color: $color2; }
        .nextbtn.Mui-disabled { background: #C1C1C1; }
        .all-set-btn { padding: 14px 2.5rem; }
      }
    }
  }

  .details {
    & { min-height: 630px; max-width: 1110px; width: 100%; background: #fff; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: "lato", sans-serif; font-size: 36px; color: $fontcolor; padding: 32px 0 0; line-height: 34px; }
    p { text-align: center; font-style: normal; font-weight: 400; font-size: 21px; line-height: 34px; color: $fontcolor; }
    .mandatory-field-text {font-style: italic; font-weight: 400; font-size: 16px; line-height: 19px;}
    .form {
      & { display: flex; justify-content: center; flex-wrap: wrap; margin: 43px auto 0px auto; width: 60%; }
      .form-detail { 
        * { box-sizing: border-box; }
        & { position: relative; flex: 0 1 46%; max-width: 46%; display: block; margin-bottom: 20px; font-weight: 700; padding: 0 2%; /* overflow: hidden; */ }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; }
        .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 18px; font-weight: 400; }
        .MuiInput-input { height: 28px; }
        .MuiSelect-select .first-menu { color: #fff; }
        
        // .form-details-text { background-color: #F4F4F4; padding: 15.6px 15px; padding: 10px 15px; max-width: 100%; }
        .form-details-text { background-color: #F4F4F4; max-width: 100%; border-radius: 3px; }
        .form-details-text .MuiInput-root { padding: 10px 15px; border-radius: 3px; border: 1px solid transparent; }
        .form-details-text .MuiInput-root.Mui-focused { border: 1px solid #303447; }
        
        
        .form-details-text.react-select div:nth-child(3) { border: none; }
        .form-details-text.react-select .react-select__placeholder { white-space: nowrap; overflow-x: hidden; padding-left: 4px; }
        .form-details-text.react-select { & { padding: 0; font-size: 18px; font-family: "lato", sans-serif; font-weight: 400; }
          div:first-child { padding-top: 6.25px; padding-bottom: 6.25px; }
        }

        .form-details-text input:focus::placeholder { color: transparent; font-weight: 300; }
        .form-details-text {
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
        .required-field { color: #303447; }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #F4F4F4 inset !important;
        }
      }
      .form-detail:last-child.school-name {
        flex: 0 1 100%; max-width: 100%; width: 100%;
      }
    }

    .details-buttons { & { display: grid; grid-template-columns: 1fr 1fr 1fr; max-width: 80%; margin: 0 auto; padding-top: 16px; padding-bottom: 3rem; } 
      .back-btn { align-self: center; }
      .cta-btn {
        & { margin: 0 auto; text-decoration: none; align-self: center; }
        a { text-decoration: none; }
        button { width: 280px; background: $color1; font-family: "lato", serif; font-size: 21px; font-weight: 700; color: #fff; border-radius: 5px; padding: 8px 0; text-align: center; text-transform: uppercase; }
        button:hover { background: $color2; transition: 0.3s; color: #fff; }
        .button-progress { margin: 4px 0 -2px; }
      }
    }
  }

  .results {
    & { min-height: 660px; background: #fff; color: $color3; width: 95%; }
    .spacer { height: 20px; }
    .selection-form { 
      &  { width: 50%; display: flex; align-items: center; margin: 20px auto; flex-wrap: wrap; justify-content: center; text-align: center; font-size: 36px; font-weight: 400; }
      .form-detail { flex: 0 1 100%; margin-right: 4%;}
      .form-text { flex: 0 1 100%; }
      .form-details-text { 
        & { background-color: #fff; border: 0px solid #efefef; border-bottom: 4px solid $color1; padding: 3px 7px; max-width: 90%; }
        .MuiInput-root { font-size: 36px; font-family: "lato", sans-serif; }
      }
    }

    h1 { width: auto; text-align: center; margin: 0 auto; font-family: 'lato', sans-serif; font-size: 36px; color: $color3; padding: 32px 32px 0; max-width: 45ch;
      .user-name { text-transform: capitalize; }
    }
    
    .progress-bar { margin: 115px auto 100px; position: relative; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: $color1; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 552px; height: 26px; margin: 0 auto; border-radius: 0; width: 100%; 
      // background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); 
      background-image: linear-gradient(to right, #D55D53 16.66%, #F7CC4F 16.67% 50%, #59945A 50.01% 100%);
    }
    .si_ass_progress .progress-line { z-index: 1; width: 2px; height: 55px; content: ' '; background: $color3; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: $color3; height: 30px; text-align: center; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #4A4A4A; bottom: 50px; left: 0; margin-left: -28px; width: 3ch; text-align: center; }
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }
    
    .pervalue h4 { position: relative; top: 92px; }
    .pervalue { max-width: 552px; margin: -68px auto; display: flex; clear: both; color: #4A4A4A; font-size: 16px; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.nimp:after { content: "21"; position: absolute; right: -9px; bottom: 22px; }
    .pervalue h4.mexpe:after { content: "63"; position: absolute; right: -9px; bottom: 22px; }
    .pervalue h4.eexpe:after { content: "126"; position: absolute; right: -13px; bottom: 22px; }
    // .pervalue h4.eexpe1:after { content: "75%"; position: absolute; right: -19px; bottom: 22px; }
    // .pervalue h4.eexpe2:after { content: "100%"; position: absolute; right: -12px; bottom: 22px; }
    // .pervalue-text h4.nimp:after { content: 'Not Aligned'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.mexpe:after { content: 'Needs Better Alignment'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.eexpe:after { content: 'Aligned'; position: absolute; right: -19px; bottom: -75px; }
    .pervalue-text { & { position: relative; display: block; margin: 0 auto; width: 100%; max-width: 552px; bottom: -68px; }
      p { position: absolute; display: inline-block; text-align: center; margin: 0 auto; font-size: 16px; vertical-align: top; color: #575757; line-height: 18px; }
    }

    .si_pro_header { max-width:552px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color:  $color3; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: -75px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4.eexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4.eexpe1:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: $color1; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: center; font-size: 18px; margin: 20px auto; max-width: 680px; width: calc(100% - 100px); background-color: #303447; padding: 30px; border-radius: 10px; color: #fff; }
    .result-text p { padding-top: 0; margin-block-start: 0; }
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
    }
    .score-section-container {
      & { display: flex; flex-direction: row; justify-content: center; align-items: center; max-width: 80%; padding: 2rem 2rem 4rem 2rem; margin: 1rem auto; flex-wrap: wrap; }
      .score-section { 
        & { flex: 0 1 33%; text-align: center; color: $color3; font-size: 18px; font-weight: 700; margin: 10px auto; }
        .score-txt { font-size: 21px; font-weight: 700; margin-bottom: 6px; }
        .score-name { font-size: 18px; font-weight: 700; margin: 0.25rem auto; }
        .score-slider { width: 70%; margin: 0 auto; margin-bottom: 16px; }
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; width: 100%; }
        .custom-bar .MuiLinearProgress-bar { background-color: #3E85C7; }
      }
    }

    
    .selection-form .form-details-text .MuiInput-root .MuiSelect-select { padding-right: 0; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: $color1; height: 5px; }

    .members-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .section {
        & { background-color: $color1; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; cursor: pointer; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 400; font-size: 18px; color: $color3; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
        .answer { flex: 1 1 285px; margin-left: 10px; text-align: right; font-weight: 700; }
        p { margin: 0; }
        .true { color: #73B76F; }
        .neutral { color: #FFB545; }
        .false { color: #FF6645; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }
  }
  
  .pdf-btn { 
    & { width: auto; padding: 0 0 3rem 0; margin: 0 auto; border: none; text-align: center; }
    .download-icon { vertical-align: sub; }
    #pdf-convert-btn { letter-spacing: 1px; background-color: #DC4A37; padding: 0.75rem 1.25rem; text-transform: uppercase; font-family: "lato", sans-serif; font-size: 20px; font-weight: 700; color: #ffffff; border-radius: 5px; border: none; cursor: pointer; }
    #pdf-convert-btn:hover { background-color: $color2; transition: 0.3s; }
  }

  .results, .dashboard {
    .areas-for-growth { & { max-width: 80ch; margin: 60px auto 30px auto; padding: 15px 15px 45px; }
      h2 { font-size: 21px; font-weight: 700; }
      .accordion {
        & { margin: 15px auto; }
        .expand-icon { color: #ffffff; fill: #ffffff; }
        .nested-expand-icon { color: #000000; fill: #000000; }
        .MuiAccordion-region { background-color: #ffffff; }
        .Mui-expanded { min-height: 48px; align-items: center; }
        .MuiAccordionSummary-content { margin: 0 auto; }
        p { margin: 0; color: $fontcolor; font-weight: 400; font-size: 18px; }
        p.white-text { color: #ffffff; font-weight: 700; font-size: 18px; line-height: 28px; }
        .section-name { text-transform: capitalize; }
      }
      .accordion.red { & { background-color: #D55D53; }
        .MuiAccordionSummary-root { background-color: #DC4A3733; }
      }
      .accordion.yellow { & { background-color: #F7CC4F; }
        .MuiAccordionSummary-root { background-color: #F7CC4F33; }
      }
      .accordion.green { & { background-color: #59945A; }
        .MuiAccordionSummary-root { background-color: #59945A33; }
      }
      .outer-accordion-details { & { padding: 0;}
        .section-title { font-weight: 700; font-size: 16px; line-height: 19px; text-transform: uppercase; color: #343333; }
        .score { font-weight: 700; }
      }
      .nested-accordion-details { 
        p { font-size: 16px; line-height: 21px; }
        .name-score-list { & { list-style-type: none; border-left: 3px solid #E66767; padding-left: 0; margin-left: 15px; }
          .user-name { min-width: 10ch; display: inline-block; padding-left: 10px; margin: 4px 0; }
          li .green { color: #59945A; text-transform: uppercase; font-size: 16px; font-weight: 700; }
          li .yellow { color: #F7CC4F; text-transform: uppercase; font-size: 16px; font-weight: 700; }
          li .red { color: #D55D53; text-transform: uppercase; font-size: 16px; font-weight: 700; }
        }
      }
    }
    .csv-download-link { text-decoration: none; }
  }

  .invite {
    & { min-height: 380px; width: 100%; max-width: 1110px; padding-bottom: 30px; background: #fff; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: "lato", sans-serif; font-size: 36px; color: $color3; padding: 67px 0 0; }
    p { text-align: center; margin: 25px auto 35px; font-size: 21px; line-height: 1.4em; color: #202020; max-width: 45ch; }
    .form {
      & { position: relative; display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px auto; width: 45%; }
      .invite-number { position: absolute; left: -50px; top: 63px; font-weight: bold; color: #C2C2C2; }
      .form-detail {
        & { flex: 0 1 46%; display: block; margin-bottom: 5px; font-weight: 700; }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; }
        .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 18px; font-weight: 400; }
        .invite-field { font-family: 'lato', sans-serif !important; background-color: #F4F4F4; }
        .invite-field .MuiInput-root { border: 1px solid transparent; padding: 5px 10px; border-radius: 3px; }
        .invite-field .MuiInput-root.Mui-focused { border: 1px solid #303447; }
        .invite-field::placeholder { font-family: 'lato', sans-serif !important; font-size: 18px !important; color: #9B9B9B; font-weight: 300; }
        .invite-field input:focus::placeholder { color: transparent; }
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
      }      
      .delete-btn { 
        & { position: absolute; right: -58px; top: 64px; }
        .delete-icon { color: #D5D5D5; font-size: 24px; cursor: pointer; }
      }
    }

    .invite-another-person {
      & { margin: 0 auto; width: 55%; position: relative; height: 5px; }
      .invite-another { & { width: 74px; background-color: #303447; position: absolute; top: -62px; cursor: pointer; right: -115px; border-radius: 5px; text-align: center; padding: 4px 6px; color: #fff; }
        .add-btn { font-size: 20px; padding: 0 6px 0 0; color: #fff; font-weight: 700; font-family: $primaryfont; }
        .invite-another-icon { vertical-align: sub; }
      }
    }

    .cta-btn {
      & { display: block; margin: 30px auto 50px; text-decoration: none; width: 270px; background: $color1; color: #fff; font-family: "lato", serif; border-radius: 5px; font-size: 21px; padding: 8px 0; text-align: center; text-transform: uppercase; font-weight: bold; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      .button-progress { margin: 4px 0 -2px; }
    }
  }

  .thank-you, .call-request-success {
    & { min-height: 380px; max-width: 926px; width: 100%; padding-bottom: 30px; background: #fff; }
    .tick { text-align: center; padding: 80px 0 0; }
    h1 { width: 100%; text-align: center; margin: 0 auto 15px; font-family: "lato", serif; font-size: 36px; color: #343333; padding: 32px 0 15px; }
    p { width: 65%; margin: 0 auto; text-align: center; font-size: 21px; color: #202020; }
    .back-btn { 
      & { text-align: center; margin: 3rem auto 45px auto; }
      a { border-bottom: 2px solid $color1; text-transform: uppercase; font-weight: bold; cursor: pointer; text-decoration: none; color: #202020; }
    }
  }
  .call-request-success {
    .back-btn { margin-bottom: 45px; }
    p { margin: 15px auto; }
  }

  .invite-container {
    & { margin: 50px auto; padding: 0 5% 30px; font-family: "lato", sans-serif; font-size: 21px; line-height: 32px; color: #FFFFFF; text-align: center; }
    h3 { font-weight: 700; font-size: 36px; }
    p { max-width: 50ch; margin: 1rem auto;}
    .height30 { height: 30px; }
    .invite-reasons {
      & { display: flex; padding: 73px 0 30px; max-width: 1400px; margin: 0 auto; }
      .reason { 
        & { flex: 0 1 33%; font-size: 21px; padding: 0 10px; max-width: 30ch; margin: 0 auto; }
        span { color: #C86F3A; font-weight: 700; }
        img { height: 61px; }
      }
    }
    .cta-btn {
      & { background-color: $color1; width: 280px; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 5px; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "lato", sans-serif; font-size: 21px; font-weight: 700; height: 50px; }
    }
  }

  .team-container {
    & { max-width: calc(880px - 10%); border-radius: 10px; margin: 50px auto; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: #ffffff; text-align: center; font-weight: 400; }
    h3 { line-height: 27px; font-size: 36px; color: #ffffff; }
    .cta-btn {
      & { width: 220px; background: $color1; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 25px; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "lato", sans-serif; font-size: 18px; font-weight: bold; }
    }
  }

  .individual-group {
    & { max-width: 400px; width: 90%; margin: 70px auto 0; display: flex; }
    button { cursor: pointer; flex: 0 1 50%; border: 0px; background: #fff; color: #1F2A44; font-family: "lato", sans-serif; font-size: 18px; font-weight: 600; padding: 16px 0; text-align: center; }
    .left { border-top-left-radius: 100px; border-bottom-left-radius: 100px; font-weight: 700; color: $color2; }
    .right { border-top-right-radius: 100px; border-bottom-right-radius: 100px; font-weight: 700; color: $color2; }
    .active { background-color: $color2; color: #fff; font-weight: 700; }
  }

  .toggle-individual-group { display: none; }

  .dashboard {
    & { min-height: 630px; background: #fff; }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: 'lato', serif; font-size: 36px; color: $color3; padding: 32px 32px 0; max-width: 45ch; }
    .asterisk { color: crimson; }
    .table-scroll-note { text-align: center; }
    
    .progress-bar { & { margin: 90px auto 100px; position: relative; }
      .pervalue h4 { position: relative; top: 92px; }
      .pervalue { max-width: 552px; margin: -68px auto; display: flex; clear: both; color: #4A4A4A; font-size: 16px; }
      // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; bottom: 35px; }
      .pervalue h4.nimp:after { content: "21"; position: absolute; right: -9px; bottom: 22px; }
      .pervalue h4.mexpe:after { content: "63"; position: absolute; right: -9px; bottom: 22px; }
      .pervalue h4.eexpe:after { content: "126"; position: absolute; right: -13px; bottom: 22px; }
      // .pervalue h4.eexpe1:after { content: "75%"; position: absolute; right: -19px; bottom: 22px; }
      // .pervalue h4.eexpe2:after { content: "100%"; position: absolute; right: -12px; bottom: 22px; }
      // .pervalue-text h4.nimp:after { content: 'Not Aligned'; position: absolute; right: -19px; bottom: -75px; }
      // .pervalue-text h4.mexpe:after { content: 'Needs Better Alignment'; position: absolute; right: -19px; bottom: -75px; }
      // .pervalue-text h4.eexpe:after { content: 'Aligned'; position: absolute; right: -19px; bottom: -75px; }
      .pervalue-text { & { position: relative; display: block; margin: 0 auto; width: 100%; max-width: 552px; bottom: -68px; }
        p { position: absolute; display: inline-block; text-align: center; margin: 0 auto; font-size: 16px; vertical-align: top; color: #575757; line-height: 18px; }
      }
    }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 552px; height: 26px; margin: 0 auto; border-radius: 0; width: 100%; 
      background-image: linear-gradient(to right, #D55D53 16.66%, #F7CC4F 16.67% 50%, #59945A 50.01% 100%);
      // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
    }
    .si_ass_progress .progress-line { z-index: 1; width: 2px; height: 55px; content: ' '; background: $color3; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: $color3; height: 30px; text-align: center; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #4A4A4A; bottom: 50px; left: 0; margin-left: -28px; width: 3ch; text-align: center; }
    
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }
    
    
    
    .pervalue h4 { position: relative; top: 50px; }
    .pervalue { max-width: 552px; margin: -68px auto; display: flex; clear: both; color: $color3; font-size: 16px; }
    .pervalue h4.nimp:after { content: '70%'; position: absolute; right: -19px; }
    .pervalue h4.mexpe:after { content: '80%'; position: absolute; right: -19px; }
    .pervalue h4.eexpe:after { content: '95%'; position: absolute; right: -19px; }

    .si_pro_header { max-width:552px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color:  $color3; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: -75px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4.eexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4.eexpe1:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: center; font-size: 18px; margin: 20px auto; max-width: 680px; width: calc(100% - 100px); background-color: #303447; padding: 30px; border-radius: 10px; color: #fff; }
    .result-text p { padding-top: 0; margin-block-start: 0; }
    .result-info { & { text-align: center; margin: 60px auto 30px; max-width: 680px; }
      h3 { color: #303447; font-size: 21px; line-height: 28px; }
      p { font-size: 18px; line-height: 28px; color: #303447; padding: 5px 15px; }
    }
    .details-table { & { padding: 0 15px; font-family: $primaryfont; }
      // .MuiPaper-root { padding: 0; overflow-x: auto; }
      .MuiPaper-root { box-shadow: none; padding: 0; }
      .MuiPaper-root::-webkit-scrollbar { height: 10px; }
      .MuiPaper-root::-webkit-scrollbar-track { box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); border-radius: 15px; height: 10px; }
      .MuiPaper-root::-webkit-scrollbar-thumb { background-color: #303447; outline: 1px solid slategrey; border-radius: 15px; }
      .table-legend { & { margin: 0 auto 15px; background-color: #F3F3F3; max-width: 60ch; padding: 10px; border-radius: 4px; vertical-align: middle; display: flex; justify-content: center; }
        p { margin: 0 auto; display: flex; align-items: center; }
        img { padding: 0; height: 25px; width: 25px; border-radius: 4px; }
      }
      table { & { position: relative; text-align: center; border-bottom: 2px solid gray; border-radius: 5px 5px 0 0; } // overflow: hidden;
        th { font-family: $primaryfont; font-weight: 700; font-size: 16px; background-color: #303447; color: #ffffff; text-align: center; border-left: 1px solid #E4E4E4; border-right: 1px solid #E4E4E4; } // overflow-x: hidden;
        th.first-column { min-width: 20ch; max-width: 20ch; }
        th.member-name { min-width: 8ch; max-width: 8ch; }
        td { font-family: $primaryfont; text-align: center; border-left: 1px solid #E4E4E4; border-right: 1px solid #E4E4E4; }
        .table-header { background-color: #D9D9D9; color: #303447; }
        .first-column { min-width: calc(32ch + 12px); max-width: calc(32ch + 12px); font-size: 12px; text-align: left; padding-right: 10px; position: sticky; left: 0; font-family: $primaryfont; font-weight: 700; } // border-right: 2px solid black; position: absolute;
        tr:nth-child(even) .first-column { background-color: #F4F4F4; }
        tr:nth-child(odd) .first-column { background-color: #FFFFFF; }
        thead:nth-child(1) .first-column { background-color: #D9D9D9; }
        .member-name { text-transform: capitalize; }
        td img { border-radius: 4px; }
        td.red-text { color: #D55D53; }
        td.yellow-text { color: #E2B225; }
        td.green-text { color: #73B76F; }
      }
    }

    .result-sections { 
      & { margin: 50px 5%; margin-bottom: 0; display: flex; justify-content: center; flex-wrap: wrap; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; font-weight: 700; font-size: 18px; margin: 10px auto; }
        .score-txt { font-size: 18px; font-weight: 700; margin-bottom: 28px; }
        .score-slider { width: 80%; margin: 0 auto; margin-bottom: 16px; }
      }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; }
    .custom-bar .MuiLinearProgress-bar { background-color: $color1; }

    table { 
      & { font-family: 'lato', sans-serif; border-collapse: collapse; width: 86%; width: auto; margin: 0 auto; font-size: 18px; font-weight: 400; color: $color3; }
      tr td:first-child { font-weight: 700; }
      tr:nth-child(even) { background-color: #F4F4F4; }
      td, th { border: 1px #dddddd;text-align: left;padding: 8px; }
      .center-cell { text-align: center; }
      .medium { font-weight: 500; }
      .hash { width: 13px; }
      .avg { text-align: center; }
      .header { border-style: none;border-bottom: 3px solid $color3; }
      .footer { 
        & { border-style: none;border-top: 3px solid $color3; color: $color1; }
        .church-average { font-size: 18px; color: $color3; }
      }
    }

    hr { width: 96%; color: #979797; }
 
    h2 { font-family: "lato", serif; font-size: 24px; font-weight: 700; color: $color1; margin-top: 50px; text-align: center; }
    .most-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 10px; overflow: hidden; }
      .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
      .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
      .most-answered-detail { font-weight: 400; font-size: 18px; color: $color3; padding: 10px 30px; width: calc(100% - 60px);}
      .most-answered-detail:nth-child(even) { background-color: #f4f4f4; }
    }

    .alignment { 
      & { max-width: calc(860px - 10%); margin: 0 auto 30px; background-color: $color1; margin: 50px auto 0; padding: 10px; border-radius: 10px; }
      .container-tabs { display: flex; flex-direction: column; position: relative; width: 100%; min-height: 400px; }
      .tab-buttons { 
        & {display: flex; width: 100%; margin: 0 auto; justify-content: center; }
        button { font-family: "lato", sans-serif; color: #fff; font-size: 18px; border-radius: 3px; font-weight: 600; text-transform: uppercase; text-align: center; padding: 15px; background: none; border: 0px; min-width: 110px; cursor: pointer; }
        .active { background-color: $color2; }
      }
      .tabs-content { flex-grow: 1; }

      .content { 
        & { width: 100%; display: none; }
        .align-details {
          & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; text-align: left; border-radius: 10px; overflow: hidden; }
          .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-radius: 10px 10px 0 0; }
          .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
          .answered-detail { 
            & { display: flex; flex-wrap: wrap; align-items: center; font-weight: 400; font-size: 18px; color: $color3; padding: 10px 15px; width: calc(100% - 30px); }
            .answer { flex: 1 1 200px; margin-left: 10px; text-align: right; font-weight: 700; }
            .true { color: #73B76F; }
            .neutral { color: #FFB545; }
            .false { color: #FF6645; }
            p { flex: 0 1 94%; }
            .more-toggle { display: block; flex: 0 1 6%; cursor: pointer; }
            .more-details { 
              & { display: none; width: 91%; margin-left: 5%; padding: 5px 2%; border-left: 3px solid #E66767; font-size: 14px; }
              .more-row { 
                & { display: flex; align-items: center; }
                p { max-width: 30%; padding: 5px; margin: 0; }
                .more-answer { text-transform: uppercase; font-weight: bold; }
              }
            }
          }
          .no-wrap { flex-wrap: nowrap; }
          .answered-detail:nth-child(even) { background-color: #f4f4f4; }
        }
        
      }
      .active-content { display: block; }
    }

    .mobile_scores {
      & { display: none; width: 90%; margin: 0 auto; }
      .individual {
        h2 { font-size: 18px; font-weight: 700; }
        & { font-size: 21px; font-weight: 700;border-bottom: 2px solid $color3;  }
        .row { 
          & { height: 40px; display: flex; align-items: center; border-top: 2px solid $color3; }
          &:last-of-type { border-bottom: 2px solid $color3; }
          .number { flex: 0 1 30px; font-size: 18px; }
          .name { flex: 1 1 auto; }
          .percent { font-size: 18px; margin-right: 10px; }
          .toggle { padding-top: 6px; }
        }
        .scores { 
          & { font-size: 18px; border-top: 2px solid $color3; }
          .section_rows { 
            & { display: flex; padding: 12px 16px; }
            &:nth-of-type(even) { background-color: #F4F4F4; }
            .section { flex: 1 1 auto; }
            .section_score { flex: 0 1 auto; }
          }
        }
      }
    }

    .urgency {
      & { margin: 0 auto; padding: 1rem 0; max-width: calc(880px - 10%); }
      h2 { font-family: "lato", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 18px; text-align: center; }
      .urgency-slider { 
        & { position: relative; background: #FAF2E980; margin: 0 auto; padding: 115px 9% 80px; border-radius: 3px; }
        h3 { position: absolute; top: 0; color: #152036; font-weight: 600; font-size: 15px; left: 7%; }

        .slider-labels {
          & { width: 100%; font-weight: 400; font-size: 13px; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }
      
      }

      .slider-points {
        & { margin-top: -20px; width: 100%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 100000; margin-left: -13px; margin-top: -12px; text-align: center; font-size: 18px; font-weight: bold; color: #fff; }
        .top-labels { position: absolute; width: 1px; height: 33px; top: -46px; margin-left: -2px; background: #1F2A44; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: bold; font-size: 18px; color: #152036; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -6px; background: $color1; position: absolute; z-index: 9; }
        }
      }

      .custom-slider.MuiSlider-root { & { z-index: 1000; color: $color1opacity42; width: 100%; margin-left: 0px; }
        span.MuiSlider-track { left: -8px !important; }
     }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 8px solid $color1opacity60; height: 0; }

      .custom-slider .MuiSlider-thumb { background: $color1; }
      .custom-slider .MuiSlider-thumb:before { background: $color1; border: 4px solid $color1; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #B8B8B8; height: 2px; }
      .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
    }

    .hurdles-enablers {
      & { max-width: calc(880px - 10%); margin: 20px auto; }
      h2 { font-family: "lato", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 18px; text-align: left; }
      .hurdles { background: #fbece9;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
      .enablers { background: #f1f8f1;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
      li { padding-bottom: 16px; font-weight: 400; font-size: 18px; }
    }

    .pdf-btn { padding-top: 3rem; }
  }

  // .testimonal-container {
  //   & { max-width: calc(880px - 4%); margin: 50px auto; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: #1F2A44; display: flex; }
  //   .avatar { margin-right: 53px; }
  //   .testimonial { 
  //     & { position: relative; color: #fff; font-family: 'lato', sans-serif; font-size: 21px; line-height: 1.35em; }
  //     .quotation-mark { position: absolute; margin-top: -50px; }
  //     .designation { 
  //       & { color: $color2; font-weight: 700; padding-top: 20px; }
  //       span {  font-weight: 400; }
  //     }
  //   }
  // }
  .testimonal-container {
    & { max-width: calc(880px - 4%); margin: 50px auto 0; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: $color3; display: flex; }
    .testimonial { 
      & { position: relative; color: #ffffff; font-family: 'lato', sans-serif; font-size: 24px; line-height: 45px; text-align: center; }
      .avatar {
        img { border-radius: 50%; width: 120px; margin-top: 50px; margin-bottom: -20px; }
      }
      .quotation-mark { 
        & { display: inline-block; font-size: 84px; color: $color1; margin-right: 15px; position: relative; }
        .quotation-block { position: absolute; right: 15px; top: -15px; }
      }
      .bottom { transform: scale(-1, -1); margin-left: 15px; }
      .designation { 
        & { color: $color1; font-weight: 700; padding-top: 32px; line-height: 1.1em; font-size: 32px; }
        span { font-weight: 600; font-size: 18px; color: #ffffff; }
      }
    }
  }

  .request-call { & { background-color: #232534; max-width: 75ch; color: #FFFFFF; margin: 60px auto 30px; padding: 30px; text-align: center; border-radius: 6px; }
    h3 { font-weight: 700; font-size: 32px; line-height: 28px; }
    p { font-weight: 400; font-size: 21px; line-height: 28px; max-width: 45ch; margin: 15px auto; }
    .cta-btn-container { & { display: flex; justify-content: center; }
      .tooltip { position: relative; display: inline-block; }
      .tooltip .tooltiptext { visibility: hidden; width: 22ch; background-color: #00000055; color: #dedede; font-family: $primaryfont; font-size: 18px; text-align: center; border-radius: 6px; padding: 8px 5px; position: absolute; z-index: 1; top: -15px; left: 30px; }
      .tooltip .tooltiptext::after { content: ""; position: absolute; top: 50%; right: 100%; margin-top: -5px; border-width: 5px; border-style: solid; border-color: transparent black transparent transparent; }
      .tooltip:hover .tooltiptext { visibility: visible; }
    }
    .cta-btn {
      button { background-color: #D55D53; color: #ffffff; font-family: $primaryfont; font-size: 21px; font-weight: 700; padding: 10px 32px; margin: 15px auto; }
      button:hover { background-color: $color2; }
      a { color: #ffffff; text-decoration: none; }
    } 
    .call-disclaimer-info-icon { position: relative; top: 5px; left: 10px; fill: #D9D9D9; cursor: pointer; }
    .call-disclaimer { & { font-size: 14px; line-height: 22px; }
      span { color: #D55D53; }
    }
  }
  .last-text { & { background-color: #232534; color: #FFFFFF; padding: 15px 30px 30px; text-align: center; max-width: 70ch; margin: 60px auto 30px; border-radius: 6px; }
    h4 { font-size: 26px; margin: 15px auto; }
    p { font-size: 21px; }
  }

  .next-steps { & { background-color: #ffffff; max-width: 80ch; margin: 45px auto; padding: 30px; border-radius: 15px; } 
    h2 { margin: 0; font-size: 21px; font-weight: 700; }
    .inner-tab-box { padding: 15px; border: 8px solid $color1; border-radius: 0 0 0.8rem 0.8rem; box-shadow: 0 0 0 2px transparent; }
    .tabs-container {
      .MuiTabs-flexContainer { justify-content: space-between; }
      .tab { background-color: #D8D8D8; color: $fontcolor; margin: 0; margin-bottom: 5px; font-weight: 700; font-family: $fontRaleway; border-radius: 5px; }
      .tab.Mui-selected { background-color: $color1; color: #ffffff; margin-bottom: 0; border-radius: 5px 5px 0 0; }
    }
    .steps-for-you { & { font-weight: 500; 
      font-family: $fontRaleway; 
    }
      .title { & { font-weight: 700; margin: 10px 0; }
        .encircled-num { background-color: $color1; border-radius: 50%; padding: 6px 12px; width: 1ch; color: #ffffff; font-size: 14px; margin-right: 5px; } }
      .info-text {
        p { margin: 0 0 0 40px; padding: 0 0 10px 0; }
        .list-info { padding: 0; }
        ul { & { margin: 5px 0 5px 40px; }
          li { padding: 5px 0; }
        }
      }
      .share-link { color: $color1; text-decoration: none; }

    }
  }

  .schedule-container {
    & { max-width: calc(830px - 10%); background: #fff; margin: 100px auto 20px; padding: 40px 5%; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: #000; text-align: center; border-radius: 10px; }
    .thumbnail img { border-radius: 50%; width: 120px; height: 120px; object-fit: cover; }
    .cta-btn {
      & {width: 280px; background: $color1; margin: 40px auto 0; color: #fff; border-radius: 5px; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { padding: 10px 20px; color: #fff; font-family: "lato", sans-serif; font-size: 21px; border-radius: 5px; font-weight: bold; letter-spacing: 2px;}
    }
  }
  .invite-legacy-container {
    & {display: flex; justify-content: center; align-items: center;}

    .invite-legacy {
      & { background-color: #FFF; margin: 50px 25px; padding: 60px 2% 30px; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: $color3; text-align: center; border-radius: 10px; width: 50ch; }
      p { margin: 0 auto; max-width: 45ch; }
      .cta-btn {
        & { width: 280px; background: $color1; margin: 1.5rem auto 1rem auto; color: #fff; padding: 5px 0; border-radius: 35px; }
        &:hover { background: $color2; color: $color3; transition: 0.3s; color: #fff; }
        a { display: block; text-decoration: none; color: #fff; }
        button { color: #fff; font-family: "lato", sans-serif; font-size: 21px; border-radius: 3px; font-weight: bold; }
      }
    }
  }

}

.btn {
  & {
    
  }
}

footer { & { min-height: 100px; }
  .bleat-logo-mobile { 
    & { display: flex; justify-content: center; padding: 2rem 0; text-align: center; width: 100%; }
    img { height: 43px; width: 105px; }
  }
}

footer { min-height: 100px; }

@media only screen and (min-width: 650px) {

  main .assessment-additional .steps .slider-container .slider-labels br { content: ""; }
  main .assessment-additional .steps .slider-container .slider-labels br:after { content: " "; }

}

@media only screen and (max-width: 1250px) {

  header {
    .logo { margin-left: 0; }
    .bleat-logo { right: 50px; }
  }
  
  .custom-slider.MuiSlider-root { padding: 12px 0; }  

}

@media only screen and (max-width: 1110px) {

  main .dashboard .urgency { overflow: hidden; }
  main .testimonal-container .testimonial .testi br { content: ""; }
  main .testimonal-container .testimonial .testi br:after { content: " "; }

}

@media only screen and (min-width: 831px) {
  // main .bleat-logo-mobile { display: none; }
  main .container.start { margin: 80px auto; border-radius: 15px; overflow-y: hidden; }
}

@media only screen and (max-width: 1100px) {
  main .call-request-success { & { max-width: 90%; }
    .details-container { 
      h1 { max-width: 90%; font-size: 32px; } 
      p { width: auto; max-width: 45ch; }
    }
  }
}

@media only screen and (max-width: 830px) {

  header {
    & { height: auto; // flex-direction: column; align-items: flex-start; 
    }
    .logo { position: relative; margin: 0 auto; left: 0; margin: 20px auto; padding: 0 0 0 10px; width: auto; }
    .assessment-title { & { margin: 10px 40px 10px auto; width: 100%; margin: 0 auto; }
      .logo-text { font-size: 24px; line-height: 32px; }
      .logo-subtext { font-size: 20px; line-height: 25px; letter-spacing: 4px; }
    }
    .bleat-logo { display: none; }
  }

  footer { & { min-height: 0; height: 0; }
    .bleat-logo-mobile { 
      & { display: flex; justify-content: center; padding: 2rem 0; text-align: center; width: 100%; }
      img { height: 43px; width: 105px; }
    }
  }
  
  main {
    .container { margin-bottom: 20px; }
    .invite-legacy-container .invite-legacy { margin-bottom: 20px; }
    .container.start { margin: 15px auto; border-radius: 10px; }
    .start {
      & { flex-direction: column; height: auto; width: 95%; }
      .video { width: 100%; height: auto; border-radius: 10px 10px 0 0; overflow-y: hidden; }
      .intro { 
        & {width: 90%; padding: 30px 5% 10px; height: auto; } // padding: 30px 5%; }
        h1 { text-align: center; width: auto; font-size: 26px; line-height: 25px; margin: 0 auto 15px; }
        h2 { text-align: center; width: 100%; max-width: 32ch; margin: auto; }
        .completion { 
          & { flex-direction: column; margin: 3rem auto 2rem auto; }
          .time-estimate { & { margin: 1rem auto; font-weight: 400; }
            .clock-icon { width: 22px; height: 24px; }
          }
          .cta { margin-bottom: 1rem; }          
        }
      }
    }
    .results {
      .score-section-container { grid-template-columns: 45% 45%; column-gap: 10%; }
      .score-section-container .score-section { width: 100%; }
      h1 { font-size: 30px; padding: 24px 16px 16px; }
      .areas-for-growth { & { margin: 15px auto 30px; }
      h2 { color: #303447; font-size: 18px; line-height: 28px; text-align: left; }}
    }
    .next-steps { padding: 20px 15px; margin: 45px 12px; border-radius: 8px; }
    .next-steps .steps-for-you .info-text {
      & { font-weight: 600; }
      ul { margin: 5px 0 5px 20px; }
    }
    .request-call { & { max-width: 45ch; margin: 60px auto 30px; padding: 20px; }
    h3 { font-size: 24px; line-height: 40px; }
    p { font-size: 18px; line-height: 28px; max-width: 30ch; }
    .cta-btn button { font-size: 21px; padding: 10px 20px; }
    }
    .last-text { padding: 15px 2% 30px; max-width: 90%; margin: 60px auto 30px; }
  }
  main .testimonal-container .testimonial .designation { p { font-weight: 600; font-size: 18px; line-height: 10px; color: #ffffff; } }
  
  main .start .video video { width: 120%; margin: 0 auto; object-fit: cover; }
  main .start .video .thumbnail { width: 100%; height: auto; border-radius: 10px 10px 0 0; }
  main .pre-assessment { 
    .pre-assessment-subheader { font-weight: 500; font-size: 16px; }
    .explanation { & { align-items: flex-start; }
      p { margin: 15px auto; font-size: 16px; font-weight: 300; }
      .marker { margin: 20px 20px 15px 5px; } }
}
  main .assessment .steps {
    .progress-percent-text { display: none; }
    // .progress-bar .bar-section .progress-percentage { color: #EE352B; }
    // .progress-bar { 
    //   & { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1rem; }
    //   .bar-section { width: 90% !important; }
    // }
  }
  main .call-request-success {
    .details-container { 
      h1 { font-size: 26px; } 
      p { font-size: 18px; max-width: 45ch; padding: 0 15px; }
    }
  }
  main .details .form { width: 80%; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 16px; } 
  main .dashboard table { font-size: 15px; }
  main .dashboard .details-table {
    .table-legend-mobile { & { display: flex; justify-content: space-around; margin: 10px auto 20px; background-color: #F3F3F3; border-radius: 5px; }
      .legend-section { & { width: 100%; padding: 10px; }
        img { margin: 10px auto; display: block; }
        p { text-align: center; margin: 10px auto; max-width: 12ch; }
      }
    }
  }
  main .dashboard .most-answered { width: 90%; }
  main .dashboard .urgency { margin: 1rem auto; max-width: 90%; }
  main .dashboard .hurdles-enablers { width: 90%; } 

}

@media only screen and (min-width: 650px) {

  // main .dashboard .urgency .urgency-slider .slider-labels .label p br { content: ""; }
  // main .dashboard .urgency .urgency-slider .slider-labels .label p br:after { content: " "; }

}

@media only screen and (max-width: 650px) {

  header { .header-wrapper { display: grid; height: auto; grid-template-columns: 0.25fr 1fr 0.25fr; /* grid-template-columns: 60px 1fr 60px; margin-left: 15px; */ }
    .logo img { height: 47px; width: auto; max-width: 60px; }
    .assessment-title {
      // & { margin: 10px 0 10px 20px; }
      & { margin: 10px 0; min-width: 200px; }
      img { height: auto; }
      .logo-text { font-family: Lato; font-size: 21px; font-weight: 700; line-height: 24px; letter-spacing: 0; text-align: center; }
      .logo-subtext { font-size: 16px; line-height: 25px; letter-spacing: 3px; }
    }
  }
  main .assessment .steps { width: 90%; padding: 0 5%; }
  main .start .video .playbtn { width: 144px; height: 144px; }
  main .start .intro h1 { text-align: center; }
  main .start .intro h2 { text-align: center; font-size: 1.25rem; line-height: 25px; }
  main .start .intro h1 br { content: initial;}
  main .start .intro h1 br:after { content: " "; }
  main .assessment .mobile-spacer { display: none; }
  main .assessment .steps {
    .progress-bar .bar-section .progress-percentage { font-size: 1rem; margin: 1rem 0 0.5rem 0.75rem; }
    .question { font-size: 21px; }
    .radio-container .slider-labels { font-size: 18px; }
  }
  
  main .results {
    & {width: 95%;}
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .areas-for-growth { & { margin: 15px auto 30px; }
      h2 { color: #303447; font-size: 18px; line-height: 28px; text-align: center; }}
    .progress-bar { width: 90%; position: relative; margin: 60px auto 100px; }
    .result-sections .score-section { font-weight: 400; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: -15px; }

    .pervalue-text { & { margin: 10px auto; position: relative; width: 100%; }
      p { display: inline-block; text-align: center; margin: 0 auto; font-size: 12px; color: #4A4A4A; }
    }
    .score-section-container {
      & { grid-template-columns: 45% 45%; column-gap: 10%; padding: 0 0 20px 0; margin-top: 0; }
      .score-section { 
        // & { margin: 10px auto; min-width: 200px; width: 75%; max-width: 350px; }
        & { margin: 10px auto; flex: 0 1 45%; min-width: 45%; }
        .score-txt { font-size: 18px; font-weight: 700; margin: 0.25rem auto; }
        .score-name { font-size: 18px; font-weight: 400; margin: 0.25rem auto; }
        .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
        
      }
    }
    .accordion p.white-text { font-size: 16px; }
  }
  main .dashboard {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    h2 { margin-top: 15px; }
    .areas-for-growth h2 { color: #303447; font-size: 18px; line-height: 28px; text-align: left; }
    .progress-bar { & { width: 90%; position: relative; margin: 75px auto 100px; } 
      .pervalue-text { & { margin: 10px auto; }
        p { font-size: 12px; } }
    }
    .details-table {
      .MuiPaper-root { padding: 0; }
      .table-legend { & { flex-direction: column; } 
        p { align-items: flex-start; width: 24ch; margin: 5px auto; }
      }
      table {
        .first-column { 
          min-width: calc(22ch + 4px); max-width: calc(22ch + 4px); 
          text-align: left; }
        th.first-column { min-width: calc(22ch + 4px) !important; max-width: calc(22ch + 4px) !important; }
        th.member-name { min-width: 8ch !important; max-width: 8ch !important; }
      }
    }
    .areas-for-growth { margin: 15px auto 30px auto; }
  }
  main .invite-legacy-container .invite-legacy { & { padding: 3rem 1rem; }
    .cta-btn { margin: 2rem auto 0 auto; }
  }

}


@media only screen and (max-width: 550px) {
  
  main .start { margin-top: 15px; }
  main .start .video { height: 70vw; }
  main .start .intro h1 { font-size: 21px; margin-bottom: 20px; }
  main .start .intro h2 { font-size: 1.1rem; }
  main .start .intro .completion img { margin: 0 4px 0 0;}
  main .assessment { margin-top: 15px; }
  main .assessment .steps .question { min-height: 180px; font-size: 18px; max-width: 100%; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 12px; }
  main .assessment .steps .slider-container .slider-labels .label { width: 14%; }
  main .assessment .steps .progress-indicator { margin: 50px auto 30px; }
  main .assessment .steps .progress-bar { grid-gap: 0; }
  main .assessment .steps .progress-bar .bar-section .bar { height: 6px; margin: 0 5px 0; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 12px; }
  main .assessment .steps .progress-indicator { font-size: 18px; }
  main .assessment .steps .slider-container .slider-labels { font-size: 12px; }
  main .assessment .steps .slider-container .slider-labels .label p.desktop { display: none; }
  main .assessment .steps .slider-container .slider-labels .label p.mobile { display: block; }
  main .assessment .steps .radio-container .slider-labels { font-size: 12px; }
  main .assessment .steps .radio-container .slider-labels .label p.desktop { display: none; }
  main .assessment .steps .radio-container .slider-labels .label p.mobile { display: block; }
  main .assessment .steps .step-buttons { margin: 120px auto 30px; padding-bottom: 20px; }
  
  main .assessment-additional { margin-top: 15px; }
  main .assessment-additional .steps .textarea-field { width: 100%; margin-left: -10px; }
  main .assessment-additional .steps .form-details-text { height: 160px; }
  main .assessment-additional .steps .question { font-size: 18px; }
  main .assessment-additional .steps .question p br { content: ""; }
  main .assessment-additional .steps .question p br:after { content: " "; }
  main .assessment-additional .steps .slider-container .slider-labels { font-size: 12px; }
  main .assessment-additional .steps .step-buttons { padding-bottom: 30px; }
  // main .assessment .steps .slider-container .custom-slider.MuiSlider-root { width: calc(100% + 25px); margin-left: -6px; }
  main .assessment .steps .slider-container .slider-labels .label p { width: 145%; margin-left: -75%; letter-spacing: 0px; }

  main .details { width: 95%; margin: 15px; }
  main .details h1 { font-size: 32px; }
  main .details p { padding: 0 5%; font-size: 18px; }
  main .details p br { content: ""; }
  main .details p br:after { content: " "; }
  main .details .form { width: 90%; }
  main .details .form .form-detail { flex: 0 1 100%; max-width: 100%; }
  main .details .form .form-detail .form-details-text { padding-right: 0; max-width: 100%;
  }
  main .details .details-buttons { & { max-width: 90%; display: flex; flex-direction: column-reverse; }
    .back-btn { align-self: flex-start; margin-top: 2rem; }
    .cta-btn { & { width: auto; }
      button { width: auto; padding: 8px 1.5rem; } }
  } 
  // main .details .form .form-detail .form-details-text.react-select { max-width: 97.5%; }

  main .results { width: 95%; max-width: 1200px; margin-top: 15px; }
  main .results .selection-form { width: max-content; max-width: 95%; flex-wrap: wrap; }
  main .results .selection-form .form-detail { width: 100%; margin-right: 0; }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; text-align: center; font-size: 18px; margin-top: 8px; }
  main .results h1 { font-size: 24px; padding: 1.5rem 1rem 1rem; width: auto; }
  main .results .result-text { max-width: fit-content; margin: 20px 5%; font-size: 18px; }
  main .results .result-sections { margin: 50px 5%; padding-bottom: 50px; }
  main .results .result-sections .score-section { flex: 0 1 100%; margin-bottom: 20px; }
  main .results .result-sections .score-section .score-txt { margin-bottom: 9px; }
  main .results .progress-bar { & { width: 90%; }
    .pervalue h4::after { font-size: 13px; bottom: 25px; }}
  main .results .si_pro_header h4 { font-size: 13px; }
  // main .results .si_pro_header h4:before { bottom: -83px !important; }
  // main .results .si_pro_header h4.nimp:before { bottom: 63px; }
  // main .results .si_pro_header h4.mexpe:before { bottom: 63px; }
  main .results .si_ass_progress .msi_score { font-size: 26px; margin-left: -23px; }
  main .results .members-answered { width: 95%; font-size: 16px; }
  main .results .members-answered .answered-detail { font-size: 14px; padding: 10px 15px; width: calc(100% - 30px); }
  main .request-call { max-width: 85%; padding: 15px; }

  main .invite-container { width: 85%; font-size: 18px; }
  main .invite-container p br { content: ""; }
  main .invite-container p br:after { content: " "; }
  main .invite-container h3 { font-size: 24px; line-height: 43px; }
  main .invite-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
  main .invite-container .invite-reasons .reason { flex: 0 1 100%; font-size: 18px; margin-bottom: 50px; margin-top: 20px; }

  main .team-container { width: 85%; font-size: 18px; }
  main .team-container h3 { font-size: 24px; line-height: 43px; }

  main .schedule-container { & { width: 85%; margin: 20px auto; font-size: 18px;}
    h2 { max-width: 16ch; margin: 18px auto; }
  }
  // main .testimonal-container { flex-wrap: wrap; padding-top: 12px; }
  // main .testimonal-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }

  main .testimonal-container { flex-wrap: wrap; padding-top: 12px; }
  main .testimonal-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .testimonal-container .testimonial .quotation-mark { font-size: 46px; }
  main .testimonal-container .testimonial .designation { font-size: 26px; }
  main .testimonal-container .testimonial .designation span { padding-top: 6px; }

  main .assessment-additional .steps .textarea { min-height: 120px; }
  main .assessment-additional .steps .textarea-field { margin-bottom: -77px; }
  main .invite { width: 95%; margin-top: 15px; }
  main .invite h1 { font-size: 32px; width: 95%; }
  main .invite h1 br { content: ""; }
  main .invite h1 br:after { content: " "; }
  main .invite p { margin: 25px 3% 35px; font-size: 18px; }
  main .invite p br { content: ""; }
  main .invite p br:after { content: " "; }
  main .invite .form { width: 65%; }
  main .invite .form .form-detail { flex: 0 1 100%; }
  main .invite .form .form-detail .invite-field { max-width: 95%; }
  main .invite .form .form-detail .error-text { bottom: auto; }
  main .invite .form .invite-number { left: -25px; top: 25px; }
  main .invite .form .delete-btn { right: auto; left: -32px; top: 64px; }
  main .invite-legacy-container .invite-legacy { font-size: 18px; }

  main .invite .invite-another-person .invite-another { top: 25px; right: -26px; left: 0; margin-left: -32px; }
  main .invite .cta-btn { margin-top: 20px; margin-right: 15%; width: 164px; min-width: 160px; padding: 4px 0; width: auto; padding: 4px 15px; font-size: 20px; }
  main .thank-you { width: 95%; }
  main .thank-you h1 { font-size: 32px; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 18px; }
  main .testimonal-container .testimonial { font-size: 18px; width: 90%; margin: 20px auto 0; text-align: center; }

  
  main .MuiSwitch-thumb { background-color: $color1 !important; position: relative; }
  main .MuiSwitch-thumb::after { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); content: ""; width: 3px; height: 3px; border: 3px solid #FFFFFF; border-radius: 50%; padding: 2px; background-color: transparent !important; }
  main .MuiSwitch-track { background-color: #4F33184D !important; opacity: 1; }
  main .individual-group { display: none; }
  main .toggle-individual-group { display: block; margin: 15px auto; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 21px; }
  main .toggle-individual-group .left { flex: 0 1 25%; text-align: right; color: #ffffff; }
  main .toggle-individual-group .right { flex: 0 1 35%; color: #ffffff; }
  main .toggle-individual-group .active { font-weight: 700; color: #ffffff; }
  main .dashboard { width: 95%; margin-top: 15px; }
  main .dashboard h1 { font-size: 24px; padding: 1.5rem 1rem 1rem; width: auto; }
  main .dashboard .mobile_scores { display: block; }
  main .dashboard .result-text { margin: 20px 5%; font-size: 18px; }
  main .dashboard .result-sections { margin: 50px 5%; padding-bottom: 0; }
  main .dashboard .result-sections .score-section { flex: 0 1 50%; }
  main .dashboard .result-sections .score-section .score-txt { margin-bottom: 10px; }
  main .dashboard .result-sections .score-section .score-name { font-weight: 400; }
  main .dashboard .progress-bar { width: 90%; }
  main .dashboard .si_pro_header h4 { font-size: 13px; }
  // main .dashboard .si_pro_header h4.nimp:before { bottom: 63px; }
  // main .dashboard .si_pro_header h4.mexpe:before { bottom: 63px; }
  main .dashboard .progress-bar .pervalue h4::after { font-size: 13px; }
  main .dashboard .si_ass_progress .msi_score { font-size: 26px; }
  main .dashboard hr { display: none; }
  // main .dashboard table { font-size: 13px; display: none; }
  main .dashboard table .footer .church-average { font-size: 13px; }
  main .dashboard table .header .center-cell { font-size: 0; }
  main .dashboard .urgency h2 { text-align: center; }
  main .dashboard .urgency .urgency-slider h3 { text-align: center; left: 0; width: 100%; }
  main .dashboard .urgency .slider-points .top-points { font-size: 13px; }
  main .dashboard .urgency .urgency-slider .slider-labels { font-size: 12px; }
  main .dashboard .most-answered { font-size: 16px; }
  main .dashboard .most-answered .most-answered-detail { font-size: 14px; }
  main .dashboard .alignment .content .align-details .answered-detail { font-size: 14px; }
  main .dashboard .alignment { width: 90%; }
  main .dashboard .alignment .tab-buttons { flex-wrap: wrap; }
  main .dashboard .alignment .tab-buttons button { font-size: 15px; min-width: 55px; padding: 10px 5px; flex: 0 1 50%; }
  main .dashboard .hurdles-enablers ul { & { padding-left: 30px; } 
    li { font-size: 14px; } }
  main .dashboard .alignment .content .align-details { font-size: 16px; }
  main .dashboard .alignment .content .align-details .answered-detail p { flex: 0 1 90%; }
  main .dashboard .alignment .content .align-details .answered-detail .more-toggle { flex: 0 1 8%; }
  main .dashboard .urgency .urgency-slider { margin: 0; background: transparent; }
  main .dashboard .urgency .custom-slider.MuiSlider-root { color: transparent; }
  main .dashboard .urgency .custom-slider .MuiSlider-track { border: 0px; }
  main .dashboard .urgency .MuiLinearProgress-root.custom-bar { background-color: #D3202B80; height: 8px; margin-top: -22px; border-radius: 5px; width: calc(100% + 3px); margin-left: -2px; }
  main .dashboard .urgency .slider-points .point { width: 8px; height: 8px; margin-top: -2px; margin-left: -5px; }
  main .dashboard {
    .details-table {
      table {
        .first-column { 
          min-width: calc(16ch + 4px); max-width: calc(16ch + 4px); 
          text-align: left; }
        th.first-column { text-align: left; min-width: calc(16ch + 4px) !important; max-width: calc(16ch + 4px) !important; }
        th.member-name { min-width: 8ch !important; max-width: 8ch !important; }
      }
    }
  }

  main .results .selection-form .form-details-text .MuiInput-root { font-size: 24px; }
  main .dashboard .alignment .content .align-details.analyze .answered-detail { flex-direction: row-reverse; }

}

@media only screen and (max-width: 550px) {

  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 14px; margin-bottom: 31px; }  
  main .start .intro .completion { margin: 36px auto 10px; width: 100%; justify-content: center;  }
  main .start .intro .completion .cta { margin: auto 15% 15px; }

}

@media only screen and (max-width: 450px) {

  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }

}

// Tabs CSS


// .tabs {
//   padding: 15px 0px;
//   text-align: center;
//   color: white;
//   width: 100%;
  
//   cursor: pointer;

//   box-sizing: content-box;
//   position: relative;
//   outline: none;
// }
// .active-tabs {
//   color: #000;
//   background: $color1;
//   border-bottom: 1px solid transparent;
//   &::before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -5px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: calc(100%);
//     height: 0px;
//     background: rgb(88, 147, 241);
//   }
// }
// button {
//   border: none;
// }

// For PDF Export 

#root.pdf-export-results {
  // background-color: #303447;
  background-color: #ffffff;
  max-width: max-content;
  max-width: 850px;
  margin: 0 auto;
  height: max-content;
  // height: 1650px;
  #pdf-conversion {
    // padding-top: 1rem;
    max-width: 100%;
    // max-height: 890px;
    margin: 0 auto;
    height: auto;
    // .container { max-width: 100%; }

    // new custom bar graphs for PDF Export 
    .non-pdf-result-sections { display: none; }
    .pdf-result-sections { display: block; }
    .next-steps { display: none; }
    .last-text { display: none; }
    main .results .pdf-btn { display: none; }
    main {
      .testimonal-container, .request-call { display: none; }
    }

    // main .container { margin: 40px auto 0; }
    main .container { margin: 10px auto 0; }

    header {
      .assessment-title .logo-text { font-size: 32px; }
      .logo img { max-height: 66px; max-width: 190px; }
    }

    main .results {
      h1 { font-size: 28px; padding: 0 30px 0; }
      .progress-bar { & { margin: 75px auto 75px; }
        .si_ass_progress .msi_score { font-size: 24px; margin-left: -22px; }
      }
      .result-text { margin: 15px auto 0; max-width: 60ch; background-color: #F3F3F3; color: #303447; }
      .areas-for-growth { & { margin: 0 auto 15px auto; padding: 0 15px 0px; max-width: 850px; }
        .print-accordion {
          .accordion { margin: 15px auto 5px; }
          .MuiAccordion-root { transition: all 0s; }
          h2 { font-size: 18px; }
          .accordion p.white-text { font-size: 17px; padding: 5px 10px; }
          .accordion p { font-size: 15px; }
          .score-info { & { padding: 0px 10px; margin: 10px 0; }
            .section-name { text-transform: capitalize; }
          }
        }
      }
    }

    .first-result-title .custom-pdf-bar-progress { background-color: $color1; padding: 0; height: 25px; }
    header { margin: 2.5rem auto 3.5rem auto; margin: 0 auto; }
    .invite-container { display: none; }
    main .container { border-radius: 0; padding-bottom: 15px; padding-top: 30px; padding-top: 0px; border-radius: 10px; }
    main .results .result-container { padding: 0; }
    #pdf-convert-btn { display: none; }
    .team-container { display: none; }
    footer {
      & { padding: 0.8rem 1rem 0.8rem; }
      .footer-logos-div {
        & { max-width: 80%; }
        .footer-logo-right { 
          & { max-width: 64px; max-height: 64px; font-size: 1rem; } 
          img { max-width: 64px; width: 58px; height: 66px; max-height: 70px; }
        }
      } 
    } 
  }
}

#root.pdf-export-dashboard {

   // background-color: #303447;
   background-color: #ffffff;
   max-width: max-content;
   max-width: 850px;
   max-width: 1200px;
   margin: 0 auto;
   height: max-content;

  #pdf-conversion {
    // padding-top: 1rem;
    max-width: 100%;
    // max-height: 890px;
    margin: 0 auto;
    height: auto;
    // .container { max-width: 100%; }

    // new custom bar graphs for PDF Export 
    .non-pdf-result-sections { display: none; }
    .pdf-result-sections { display: block; }
    .next-steps { display: none; }
    .last-text { display: none; }
    main .dashboard .pdf-btn { display: none; }
    main .container { margin: 0 auto; }

    main .dashboard {
      h1 { padding: 0 30px; font-size: 28px; }
      .result-info {
        & { margin: 15px auto 5px; }
        h3 { margin-bottom: 5px; }
        p { margin: 5px auto 15px; }
      }
      .progress-bar {
        margin: 70px auto 65px;
      }
      .si_ass_progress .msi_score { font-size: 28px; margin-left: -24px; }
      .result-info { max-width: unset; }
      .result-text { max-width: 820px; background-color: #F3F3F3; color: #303447; }
      .details-table table th.MuiTableCell-root { line-height: 1; background-color: #F3F3F3; color: #303447; }
      .details-table table td.MuiTableCell-root { line-height: 1; }
    }
    header { & { height: 105px; }
      .header-wrapper { height: 105px; }
      .logo img { max-height: 60px; }
      .assessment-title .logo-text { font-size: 32px; line-height: 36px; }
      .assessment-title .logo-subtext { font-size: 18px; line-height: 21px; }
    }
    footer .bleat-logo-mobile { padding: 1.5rem 0; }

  }
}


